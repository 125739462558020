import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    Renderer2,
} from "@angular/core";
import { TransitionPromise } from "@uirouter/core";
import { BreakpointWidth, BUTTON_LOADER_CLASS } from "core/constants";

@Directive({
    selector: "[promiseBtn]",
})

/** custom loading promise button */
export class CbmPromiseBtn<T> {
    @Input("promiseBtn") public promise: Promise<T> | TransitionPromise;
    @Input() isEligibilityPage: boolean;
    @Input() public isDynamicText: boolean;
    @Input() public ctaText: string;

    public isMobileView: boolean;
    public isTabletView: boolean;

    private spinnerElement: HTMLElement;
    private buttonContent: string; // to store the buton text

    constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

    public createKeyframes() {
        const keyframes = `
          @keyframes ellipsis-one {
            0% {
                left: -14px;
                transform: scale(0); 
            }

            25% {
                left: 14px;
                transform: scale(1);
            }

            50% {
                left: 50px;
            }

            75% {
                left: 86px;
                transform: scale(1);
            }

            100% {
                left: 100px;
                transform: scale(0);
                }
            }`;

        const styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        styleSheet.innerText = keyframes;
        document.head.appendChild(styleSheet);
    }

    @HostListener("click") public onClick(): void {
        this.isMobileView = window.innerWidth < BreakpointWidth.TABLET;
        this.isTabletView = window.innerWidth >= BreakpointWidth.TABLET;
        this.buttonContent = this.elementRef.nativeElement.textContent;
        this.checkButtonLoaderState();

        if (!this.isEligibilityPage) {
            // to hide the button text when spinner starts
            this.elementRef.nativeElement.textContent = "";
            //create spinner container
            this.spinnerElement = this.renderer.createElement("div");
            if (this.isMobileView) {
                this.renderer.setAttribute(
                    this.spinnerElement,
                    "style",
                    `
            position: relative;
            top: 60%;
            bottom: 60%;
            left: 90%;
            right: 80%;
            transform: translate(-90%, -10%);
        `
                );
            } else {
                this.renderer.setAttribute(
                    this.spinnerElement,
                    "style",
                    `
            position: relative;
            top: 60%;
            bottom: 60%;
            left: 90%;
            right: 80%;
            transform: translate(-90%, -10%);
            transition: opacity .3s ease-in-out, visibility .3s ease-in-out, transform .3s ease-in-out;
        `
                );
            }

            // create spinner
            const spinner: ElementRef = this.renderer.createElement("div");
            this.renderer.setAttribute(
                spinner,
                "style",
                `
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%; /* Adjust the size as needed */
            height:100%;
            transition: opacity .2s ease-in-out, visibility .2s ease-in-out, transform .2s ease-in-out;
        `
            );

            // Create and append three white loading circles to the spinner element
            for (let i: number = 0; i < 3; i++) {
                this.createKeyframes();
                const circle: ElementRef = this.renderer.createElement("div");
                if (
                    this.elementRef.nativeElement.classList.contains(
                        "promise-btn-secondary"
                    )
                ) {
                    this.renderer.setAttribute(
                        circle,
                        "style",
                        `
                    content: '';
                    width: 14px;
                    height: 14px;
                    background-color: #0D62FF;
                    border-radius: 50%;
                    margin: 4px 8px; /* Adjust the margin as needed to control spacing */
                    animation: ellipsis-one 1.4s infinite ease-in-out ${i * 0.32
                        }s;
                `
                    );
                } else {
                    this.renderer.setAttribute(
                        circle,
                        "style",
                        `
                    content: '';
                    width: 14px;
                    height: 14px;
                    background-color: #fff;
                    border-radius: 50%;
                    margin: 4px 8px; /* Adjust the margin as needed to control spacing */
                    animation: ellipsis-one 1.4s infinite ease-in-out ${i * 0.32
                        }s;
                `
                    );
                }
                this.renderer.appendChild(spinner, circle);
            }

            // Append the spinner to the container and then to the button
            this.renderer.appendChild(this.spinnerElement, spinner);
            this.renderer.appendChild(
                this.elementRef.nativeElement,
                this.spinnerElement
            );

            // Disable the button
            this.elementRef.nativeElement.disabled = true;

            // wait for promise to be defined
            setTimeout(() => {
                if (this.promise) {
                this.promise
                    .then(() => {
                        // Enable the button, restore the original text content, and remove the spinner when the Promise is resolved
                        this.elementRef.nativeElement.disabled = false;
                        this.elementRef.nativeElement.textContent = this.isDynamicText ? this.ctaText : this.buttonContent;
                        if (this.spinnerElement) {
                            this.renderer.removeChild(
                                this.elementRef.nativeElement,
                                this.spinnerElement
                            );
                        }
                    })
                    .catch(() => {
                        // Enable the button, restore the original text content, and remove the spinner when the Promise is rejected
                        this.elementRef.nativeElement.disabled = false;
                        this.elementRef.nativeElement.textContent = this.buttonContent;
                        if (this.spinnerElement) {
                            this.renderer.removeChild(
                                this.elementRef.nativeElement,
                                this.spinnerElement
                            );
                        }
                    });
                } else {
                    this.elementRef.nativeElement.disabled = false;
                        this.elementRef.nativeElement.textContent = this.buttonContent;
                        if (this.spinnerElement) {
                            this.renderer.removeChild(
                                this.elementRef.nativeElement,
                                this.spinnerElement
                            );
                        }
                }
            });
        }
    }
    private checkButtonLoaderState(): void {
        // Check if the element has the 'BUTTON_LOADER_CLASS' class
        if (this.elementRef.nativeElement.classList.contains(BUTTON_LOADER_CLASS)) {
            this.elementRef.nativeElement.setAttribute("aria-disabled", "true");
        } else {
            // Remove 'aria-disabled' unless the element has 'xmAriaDisabled' attribute
            if (!this.elementRef.nativeElement.hasAttribute("xmAriaDisabled")) {
                this.elementRef.nativeElement.removeAttribute("aria-disabled");
            }
        }
    }
}
